/**=====================
  89. Tile CSS Start
==========================**/

.tile {
    min-height: 50px;
}

.tile-title-link {}

.tile-title {}

.tile-body {
}

.tile-content {
    font-weight: 400 !important;

    .tile-counter {
        color: $secondary-color;
        transition: all .5s ease-in-out;

        &:hover {
            font-size: 1.25em;
            font-weight: 700;
            line-height: .2;
        }
    }
}

.tile-footer {
    position: absolute;
    left: 10px;
    bottom: 5px;
    font-size: 10px;
    color: gray;
}

/**=====================
  89. Tile CSS End
==========================**/