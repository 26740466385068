.Toastify {
    .Toastify__toast-container {
        .success {
            background: $success-color !important;
            color: $light-gray;
            .Toastify__toast-body{
                .Toastify__toast-icon svg {
                    fill: $success-accent;
                }
            }
            .Toastify__progress-bar {
                background: $success-accent;
            }
            .Toastify__close-button {
                opacity: .6;
                svg {
                    fill: $success-accent;
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
        .warning {
            background: $warning-color !important;
            color: $warning-accent;
            .Toastify__toast-body{
                .Toastify__toast-icon svg {
                    fill: $warning-accent;
                }
            }
            .Toastify__progress-bar {
                background: $warning-accent;
            }
            .Toastify__close-button {
                opacity: .6;
                svg {
                    fill: $warning-accent;
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
        .error {
            background: $danger-color !important;
            color: #530101;
            .Toastify__toast-body{
                white-space: pre-line;
                .Toastify__toast-icon svg {
                    fill: $error-accent;
                }
            }
            .Toastify__progress-bar {
                background: $error-accent;
            }
            .Toastify__close-button {
                opacity: .6;
                svg {
                    fill: $error-accent;
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
        .info {
            background: $info-color !important;
            color: #fefefec7;
            .Toastify__toast-body{
                .Toastify__toast-icon svg {
                    fill: $info-accent;
                }
            }
            .Toastify__progress-bar {
                background: $info-accent;
            }
            .Toastify__close-button {
                opacity: .6;
                svg {
                    fill: $light;
                }
                &:hover {
                    opacity: 1;
                }
            }
        }

    }
}